export enum GdprFormControlNameEnum {
  DOT_DIGITAL_CONSENT = 'dotDigitalConsent',
  NEWS_CONSENT = 'newsConsent',
  EVENTS_CONSENT = 'eventsConsent',
  TITLE = 'title',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  JOB_TITLE = 'jobTitle',
  COMPANY_NAME = 'companyName'
}
