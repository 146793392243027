
export class GdprContactModel {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  companyName: string;

  constructor(id: number, title: string, firstName: string, lastName: string, jobTitle: string, companyName: string) {
    this.id = id;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.jobTitle = jobTitle;
    this.companyName = companyName;
  }
}
