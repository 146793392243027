import {Title} from "../../enums/title.enum";

export class GdprResponseModel {
  title: Title | null;
  firstName: string | null;
  lastName: string | null;
  jobTitle: string | null;
  companyName: string | null;
  newsConsent: boolean | null;
  eventsConsent: boolean | null;
  dotDigitalConsent: boolean;
  modifiedAt: Date;


  constructor(
    title: Title | null,
    firstName: string | null,
    lastName: string | null,
    jobTitle: string | null,
    companyName: string | null,
    newsConsent: boolean | null,
    eventsConsent: boolean | null,
    dotDigitalConsent: boolean,
    modifiedAt: Date
  ) {
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.jobTitle = jobTitle;
    this.companyName = companyName;
    this.newsConsent = newsConsent;
    this.eventsConsent = eventsConsent;
    this.dotDigitalConsent = dotDigitalConsent;
    this.modifiedAt = modifiedAt;
  }


}
