import {GdprResponseModel} from "./gdpr-response.model";

export class GdprRequestDetailsModel {
  createdAt: Date;
  expirationDate: Date;
  gdprResponse?: GdprResponseModel | null;

  constructor(createdAt: Date, expirationDate: Date, gdprResponse?: GdprResponseModel | null) {
    this.gdprResponse = gdprResponse;
    this.createdAt = createdAt;
    this.expirationDate = expirationDate;
  }
}
